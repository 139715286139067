.general-text-14 {
  font-size: 14px;
  color: var(--gray-typography);
}

.general-text-18 {
  font-size: 18px;
  color: var(--gray-typography);
}

.general-title-20 {
  color: var(--blue-typography);
  font-weight: bold;
  font-size: 20px;
}

.general-title-24 {
  color: var(--blue-typography);
  font-weight: bold;
  font-size: 24px;
}

.alert-general {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  * {
    font-size: 14px;
  }
}
